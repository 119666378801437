<template>
    <div id="page-container" class="">
        <main class="min-h-screen w-full">
            <router-view />
        </main>
        <ReloadRequired v-if="isReloadRequired" />
        <BottomFooter />
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

import BottomFooter from '@/components/BottomFooter.vue'
import ReloadRequired from '@/components/ReloadRequired.vue'
import { useAnalytics } from '@/composables/analytics.js'

const router = useRouter()
const { analytics } = useAnalytics()

const isReloadRequired = ref(false)

router.onError((error) => {
    if (error?.message.includes('dynamically imported module')) {
        isReloadRequired.value = true
        analytics.track('Reload Required')
    }
})

router.afterEach((to) => {
    analytics.page(to.path, {
        ...to.params,
        ...to.query,
    })
})

//Add Instatus Widget
useInstatus()
</script>
