<template>
    <ModalStyleProvider :is-open="isModalOpen" @close="reset">
        <template #title> Provider Search </template>
        <template #breadcrumbs>
            <ProviderSearchResultsBreadcrumbs :is-fetching="isFetching" />
        </template>
        <template #default="{ close }">
            <ProviderSearchResults
                v-if="currentRelationship && (currentQuery.type === 'EIN' || currentQuery.type?.startsWith('NPI'))"
                :relationships="currentRelationship"
                :current-query="currentQuery"
                :active-type-one-page="activeTypeOnePage"
                :type-one-pages="typeOnePages"
                :active-type-two-page="activeTypeTwoPage"
                :type-two-pages="typeTwoPages"
                @load-type-ones="fetchEINTypeOneNPIs"
                @load-npi-type-ones="fetchNPITypeOneNPIs"
                @set-active-type-one-page="fetchEINTypeOneNPIs"
                @set-active-type-two-page="fetchEINTypeTwoNPIs"
                @new-tags="updateTags"
            >
                <template v-if="copyNPITypeTwo" #npi-type-two-actions>
                    <ButtonCopyTiny class="ml-2" :data="copyNPITypeTwo" />
                </template>
                <template v-if="copyNPITypeOne" #npi-type-one-actions>
                    <ButtonCopyTiny :data="copyNPITypeOne" />
                </template>
            </ProviderSearchResults>
            <ProviderSearchResultsMultipe
                v-if="currentRelationship?.eins && currentQuery.type === 'TAG'"
                :relationships="currentRelationship?.eins"
                :current-query="currentQuery"
                @new-tags="updateTags"
            />
            <ProviderSearchResultsMultipe
                v-if="currentRelationship && currentQuery.type === 'ORG'"
                :relationships="currentRelationship"
                :current-query="currentQuery"
                @new-tags="updateTags"
            />
            <ProviderSearchFetchingIndicator :is-active="isFetching" />
            <ProviderSearchResultsEmpty v-if="isEmpty" />
            <ProviderSearchResultsError v-if="isError" />
            <div v-if="!isFetching && !isError && !currentRelationship" class="absolute inset-0" @click="close" />
        </template>
    </ModalStyleProvider>
</template>

<script setup>
import { ref, reactive, computed, watch, nextTick, onMounted } from 'vue'
import { useMagicKeys, whenever } from '@vueuse/core'
import { uniqBy } from 'lodash'
import { useRoute } from 'vue-router'

import ProviderSearchResultsError from '@/components/provider-search/ProviderSearchResultsError.vue'
import ProviderSearchResultsEmpty from '@/components/provider-search/ProviderSearchResultsEmpty.vue'
import ProviderSearchFetchingIndicator from '@/components/provider-search/ProviderSearchFetchingIndicator.vue'
import ProviderSearchResults from '@/components/provider-search/ProviderSearchResults.vue'
import ProviderSearchResultsMultipe from '@/components/provider-search/ProviderSearchResultsMultiple.vue'
import ProviderSearchResultsBreadcrumbs from '@/components/provider-search/ProviderSearchResultsBreadcrumbs.vue'
import ModalStyleProvider from '@/components/modals/styles/ModalStyleProvider.vue'
import ButtonCopyTiny from '@/components/buttons/ButtonCopyTiny.vue'

import { useModalProviderSearch } from '@/composables/modalProviderSearch'
import { useAPI } from '@/composables/api'
import { useAnalytics } from '@/composables/analytics.js'

const props = defineProps({
    isPeerBenchmarks: {
        type: Boolean,
        default: false,
    },
})

const relationships = reactive({
    initial: null,
})
const isFetching = ref(false)
const isError = ref(false)
const isEmpty = ref(false)
const typeOnePages = ref([])
const activeTypeOnePage = ref(1)
const typeTwoPages = ref([])
const activeTypeTwoPage = ref(1)
const pageSize = ref(10000)
const copyNPITypeTwo = ref(null)
const copyNPITypeOne = ref(null)
const route = useRoute()

const { isModalOpen, modalQuery, launch, currentQuery } = useModalProviderSearch()
const { relationshipsAPI } = useAPI()
const { analytics } = useAnalytics()

const currentRelationship = computed(() => relationships[modalQuery.value?.child])

async function fetchRelationships() {
    if (isFetching.value) return
    if (!modalQuery.value?.q) return

    await nextTick()

    isError.value = false
    isEmpty.value = false

    if (currentRelationship.value) return

    isError.value = false
    isEmpty.value = false
    isFetching.value = true

    await nextTick()

    if (currentQuery.value?.type === 'EIN') {
        return fetchEIN(props.isPeerBenchmarks)
    }

    if (currentQuery.value?.type === 'ORG') {
        return fetchORG()
    }

    if (currentQuery.value?.type === 'TAG') {
        return fetchTag()
    }

    return fetchNPI(props.isPeerBenchmarks)
}

const setTypeTwoPages = (pageCount) => {
    typeTwoPages.value = []
    for (let i = 1; i < pageCount + 1; i++) {
        typeTwoPages.value.push(i)
    }
}

const setTypeOnePages = (pageCount) => {
    typeOnePages.value = []
    for (let i = 1; i < pageCount + 1; i++) {
        typeOnePages.value.push(i)
    }
}

async function fetchEINTypeTwoNPIs(pageNumber) {
    try {
        isFetching.value = true

        let data = await relationshipsAPI(
            `/ein/${currentQuery.value?.q}?includeTypeTwoNPIs=true&typeTwoPageSize=${pageSize.value}&typeTwoPageNumber=${pageNumber}`,
            {
                method: 'GET',
            }
        )

        if (!data.relationships) {
            return
        }

        let relate = relationships[modalQuery.value.child]
        relate.typeTwoNPIs = data.relationships.typeTwoNPIs

        setTypeTwoPages(data.typeTwoPagination.count)

        relationships[modalQuery.value.child] = mapNetworksToPayors(await fetchMultipleNPIs(relate))
    } catch (error) {
        console.error(error.message)
        isError.value = true
    } finally {
        isFetching.value = false
    }
}

async function fetchEINTypeOneNPIs(pageNumber) {
    try {
        isFetching.value = true

        let data = await relationshipsAPI(
            `/ein/${currentQuery.value.q}?includeTypeOneNPIs=true&typeOnePageSize=${pageSize.value}&typeOnePageNumber=${pageNumber}`,
            {
                method: 'GET',
            }
        )

        if (!data.relationships) {
            return
        }

        let relate = relationships[modalQuery.value.child]
        relate.typeOneNPIs = data.relationships.typeOneNPIs
        setTypeOnePages(data.typeOnePagination?.count)

        copyNPITypeOne.value = data.relationships?.typeOneNPIs?.map((i) => i.npi).join(',\n')

        relationships[modalQuery.value.child] = relate
    } catch (error) {
        console.error(error.message)
        isError.value = true
    } finally {
        isFetching.value = false
    }
}

async function fetchNPITypeOneNPIs(pageNumber) {
    try {
        isFetching.value = true

        let data = await relationshipsAPI(
            `/npi/${currentQuery.value.q}?includeTypeOneNPIs=true&typeOnePageSize=${pageSize.value}&typeOnePageNumber=${pageNumber}`,
            {
                method: 'GET',
            }
        )

        if (!data.relationships) {
            return
        }

        let relate = relationships[modalQuery.value.child]
        relate.typeOneNPIs = data.relationships.typeOneNPIs
        copyNPITypeOne.value = data.relationships?.typeOneNPIs?.map((i) => i.npi).join(',\n')

        setTypeOnePages(data.typeOnePagination?.count)

        relationships[modalQuery.value.child] = relate

        analytics.track({
            event: 'Provider Directory Searched',
            properties: {
                npi_one: currentQuery.value.q,
            },
        })
    } catch (error) {
        console.error(error.message)
        isError.value = true
    } finally {
        isFetching.value = false
    }
}

async function fetchEIN() {
    try {
        isFetching.value = true

        let data = await relationshipsAPI(
            `/ein/${currentQuery.value.q}?includePayors=true&includeNetworks=true&includeTags=true&includeOrgNames=true&includeTypeTwoNPIs=true&typeTwoPageSize=${pageSize.value}&typeTwoPageNumber=1`,
            {
                method: 'GET',
            }
        )

        if (!data) {
            isEmpty.value = true

            return false
        }

        copyNPITypeTwo.value = data.relationships?.typeTwoNPIs?.map((i) => i.npi).join(',\n')

        setTypeTwoPages(data?.typeTwoPagination?.count)
        relationships[modalQuery.value.child] = mapNetworksToPayors(await fetchMultipleNPIs(data?.relationships))

        analytics.track({
            event: 'Provider Directory Searched',
            properties: {
                ein: currentQuery.value.q,
            },
        })
    } catch (error) {
        console.error(error.message)
        isError.value = true
    } finally {
        isFetching.value = false
    }
}

async function fetchORG() {
    try {
        isFetching.value = true

        let data = await relationshipsAPI(
            `/eins?orgName=${currentQuery.value.q}&includeTags=true&includeOrgNames=true&includeEINs=true`,
            {
                method: 'GET',
            }
        )

        if (!data) {
            isEmpty.value = true

            return
        }

        relationships[modalQuery.value.child] = data.results

        analytics.track({
            event: 'Provider Directory Searched',
            properties: {
                org: currentQuery.value.q,
            },
        })
    } catch (error) {
        console.error(error.message)
        isError.value = true
    } finally {
        isFetching.value = false
    }
}

async function fetchTag() {
    try {
        isFetching.value = true

        let data = await relationshipsAPI(`/tags/${currentQuery.value.q}?includeEin=true&includeOrgNames=true`, {
            method: 'GET',
        })

        if (!data.results.eins && !data.results.npis) {
            isEmpty.value = true

            return
        }

        relationships[modalQuery.value.child] = data.results

        analytics.track({
            event: 'Provider Directory Searched',
            properties: {
                tag: currentQuery.value.q,
            },
        })
    } catch (error) {
        console.error(error.message)
        isError.value = true
    } finally {
        isFetching.value = false
    }
}

async function fetchNPI(includeTypeOneNPIs) {
    try {
        isFetching.value = true

        console.log('includeTypeOneNPIs: ' + includeTypeOneNPIs)

        if (includeTypeOneNPIs === null || includeTypeOneNPIs === undefined) {
            includeTypeOneNPIs = true
        }

        console.log('includeTypeOneNPIs: ' + includeTypeOneNPIs)

        let data = await relationshipsAPI(
            `/npi/${currentQuery.value.q}?includePayors=true&includeNetworks=true&includeTags=true&includeOrgNames=true&includeEINs=true&includeTypeTwoNPIs=true&includeTypeOneNPIs=${includeTypeOneNPIs}`,
            {
                method: 'GET',
            }
        )

        if (!data) {
            isEmpty.value = true

            return
        }

        relationships[modalQuery.value.child] = mapNetworksToPayors(data?.relationships)

        if (data.relationships?.typeTwoNPIs) {
            copyNPITypeTwo.value = data.relationships?.typeTwoNPIs?.map((i) => i.npi).join(',\n')
        }

        if (data.relationships?.typeOneNPIs) {
            copyNPITypeOne.value = data.relationships?.typeOneNPIs?.map((i) => i.npi).join(',\n')
        }

        analytics.track({
            event: 'Provider Directory Searched',
            properties: {
                npi: currentQuery.value.q,
            },
        })
    } catch (error) {
        console.error(error.message)
        isError.value = true
    } finally {
        isFetching.value = false
    }
}

async function fetchMultipleNPIs(relationships) {
    if (!relationships.typeTwoNPIs) return relationships

    const typeTwoNPISIDS = relationships.typeTwoNPIs.map((n) => n.npi)

    try {
        let data = await relationshipsAPI(`/npis?includePayors=true&includeNetworks=true`, {
            method: 'POST',
            body: typeTwoNPISIDS,
        })

        const newPayors = []
        const newNetworks = []

        data.results.forEach((npi) => {
            if (npi.relationships.payors) {
                newPayors.push(...npi.relationships.payors)
            }

            if (npi.relationships.networks) {
                newNetworks.push(...npi.relationships.networks)
            }
        })

        if (newPayors.length && relationships.payors?.length) {
            const existingPayorIds = relationships.payors.map((p) => p.id)
            const uniquePayors = uniqBy(newPayors, 'id')

            relationships.payors.push(
                ...uniquePayors
                    .filter((p) => !existingPayorIds.includes(p.id))
                    .map((p) => ({
                        ...p,
                        fromNPITwo: true,
                    }))
            )
        }

        if (newNetworks.length && relationships.networks?.length) {
            const existingNetworkIds = relationships.networks.map((p) => p.id)
            const uniqueNetworks = uniqBy(newNetworks, 'id')

            relationships.networks.push(
                ...uniqueNetworks
                    .filter((p) => !existingNetworkIds.includes(p.id))
                    .map((p) => ({
                        ...p,
                        fromNPITwo: true,
                    }))
            )
        }
    } catch (e) {
        console.error(e)
    }

    return relationships
}

function mapNetworksToPayors(relationships) {
    if (!relationships.payors) return relationships

    const uniqueNetworks = uniqBy(relationships.networks, 'id')
    relationships.payors = relationships.payors
        .map((p) => ({
            ...p,
            networks: uniqueNetworks.filter((n) => n.payorId === p.id),
        }))
        .filter((p) => {
            return p.networks?.length
        })

    // Sort payors and networks
    relationships.payors?.sort((a, b) => a.name.localeCompare(b.name))
    relationships.networks?.sort((a, b) => a.name.localeCompare(b.name))

    return relationships
}

function reset() {
    isFetching.value = false
    isError.value = false
    isEmpty.value = false

    copyNPITypeTwo.value = null
    copyNPITypeOne.value = null

    Object.assign(relationships, {
        initial: null,
    })
}

function updateTags(newTags) {
    Object.assign(relationships[modalQuery.value?.child], newTags)
}

watch(
    () => modalQuery.value?.child,
    () => fetchRelationships()
)

watch(
    () => modalQuery.value?.t,
    () => {
        reset()
        fetchRelationships()
    }
)

const { cmd_p } = useMagicKeys({
    passive: false,
    onEventFired(e) {
        if (e.metaKey && e.key === 'p' && e.type === 'keydown') e.preventDefault()
    },
})

whenever(cmd_p, () => {
    if (['signal', 'signal-search', 'signal-market-benchmarks', 'signal-peer-benchmarks'].includes(route.name)) return

    launch()

    analytics.track({
        event: 'Provider Directory Opened From Shortcut',
    })
})

onMounted(async () => {
    await fetchRelationships()
})
</script>
