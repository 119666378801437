/*eslint no-undef: "off"*/
import { useScriptTag } from '@vueuse/core'

export function useInstatus() {
    if (!import.meta.env.PROD) {
        return
    }

    useScriptTag(
        'https://serif-health.instatus.com/en/e790e264/widget/script.js',
        () => {
            console.info('Instatus Widget loaded')
        },
        {
            defer: true,
            async: false,
        }
    )

    return {}
}
